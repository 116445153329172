import React, { useState } from "react";

import links from "./links";
import brands from "./brands";
import { Link } from "gatsby";
import Logo from "../images/logo.png";

import styled from 'styled-components';
import tw from 'twin.macro';
import hamburger from "../images/nav.svg";

const NavContainer = tw.div`
    flex
    justify-center
    w-full
    bg-white
`

const Hamburger = styled.button`
    ${tw`border-0 lg:hidden`}
`

const NavBox = ({ children }) => <div tw="flex items-center justify-between lg:flex-col list-none py-3 px-5  max-w-screen-2xl w-full">{ children }</div>


const NavArea = tw.ul`
    hidden
    lg:flex
    items-center
    justify-between
    pl-0
    my-2
    list-none
    w-auto
    space-x-2
`

const NavItem = tw.li`
    block
    w-auto
`

const BrandLink = styled(Link)`

    img {
        filter: grayscale(1);
        opacity: 0.8;
        cursor: pointer;
    }
    display: block;
    cursor: pointer;

    &:hover {

        cursor: pointer;

        img {
            cursor: pointer;
            filter: grayscale(0);
            opacity: 0.8;
        }
    }

    &.active {
        img {
            filter: grayscale(0);
            opacity: 1;
        }
    }


    ${tw`transition duration-300 ease-in-out opacity-80 hover:opacity-100`}

`
const NavLink = styled(Link)`
    ${tw`py-3 lg:py-4 lg:px-5 text-base font-medium text-gray-700 transition duration-300 ease-in-out opacity-80 font-sans whitespace-nowrap hover:text-black hover:opacity-100`}
    &.active {
        ${tw`text-red-500 `}
    }
`


const MobileNav = styled.div`
    ${tw`
        fixed
        top-0
        z-10
        left-0
        h-screen
        w-full
        bg-black
        lg:hidden
        bg-opacity-80
        transition-opacity
        duration-100
    `}

    pointer-events: none;

    &.nav-open {
        opacity: 1;
        pointer-events: all;
    }

    &.nav-closed {
        ${tw`duration-700`}
        opacity: 0;
    }

`

const NavMotion = styled.div`

    ${tw`bg-white px-7 h-full transition-transform duration-700`}

    width: calc(100% - 80px);

    .nav-open & {
        transform: translateX(0);
    }

    .nav-closed & {
        ${tw`duration-300`}
        transform: translateX(-100%);
    }
`


const MobileLinks = styled.div`
    ${tw`
        flex
        flex-col
        justify-between
        pt-8
        font-sans
        font-medium
        h-full
        pb-16
    `}

    hr {
        ${tw`my-1`}
    }
`

const CloseContain = styled.div`
    ${tw`fixed top-4 right-1 z-10 text-white mr-6 text-2xl`}
    .nav-open & { opacity: 1; }
    .nav-closed & { opacity: 0; }
`

const LogoLink = styled(Link)`
    ${tw`md:py-5`}
`

const Nav = () => {

    const [navOpen, setNavOpen] = useState(false);

    return (
        <NavContainer>
            <NavBox>
                <LogoLink
                    to={ `/` }
                >
                    <img src={ Logo } width={ 300 } alt="Adjmi Apparel Group Logo" />
                </LogoLink>
                <NavArea>
                    { links.map(link => (
                        <NavItem key={ link.url }>
                            <NavLink
                                activeClassName="active"
                                to={ `${link.url}` }
                            >
                                { link.text }
                            </NavLink>
                        </NavItem>
                    )) }
                </NavArea>

                <NavArea>
                    { brands.map(brand => (
                        <NavItem key={ brand.url }>
                            <BrandLink
                                activeClassName="active"
                                to={ `/brands/${brand.url}` }
                            >
                                <img src={brand.img} width="98" alt='Brand Logo' />
                            </BrandLink>
                        </NavItem>
                    )) }
                </NavArea>

                <Hamburger onClick={ () => setNavOpen(true) }><img width='30' src={ hamburger } alt="Hamburger" /></Hamburger>
            </NavBox>

            <MobileNav className={ navOpen ? 'nav-open' : 'nav-closed' }>
                <NavMotion className={ navOpen ? 'nav-open' : 'nav-closed' }>
                    <MobileLinks>
                        <Link
                            className="logo"
                            tw=''
                            to={ `/` }
                        >
                            <img src={ Logo } width={ 251 } alt="Logo" />
                        </Link>
                        { links.map(link => (
                            <NavItem key={ link.url }>
                                <NavLink
                                    activeClassName="active"
                                    to={ `${link.url}` }
                                >
                                    { link.text }
                                </NavLink>
                            </NavItem>
                        )) }
                        <hr />
                    </MobileLinks>
                </NavMotion>
                <CloseContain>
                    <a onClick={ () => setNavOpen(false) }>X</a>{/* eslint-disable-line */}
                </CloseContain>
            </MobileNav>

        </NavContainer>
    )
}

export default Nav
