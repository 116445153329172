// data

import al1veLogo from '../images/logos/al1ve.png'
import apanaLogo from '../images/logos/apana.png'
import copperLogo from '../images/logos/copper.png'
import filaLogo from '../images/logos/fila.png'
import layer8Logo from '../images/logos/layer8.png'
import skechersLogo from '../images/logos/skechers.png'
import skoraLogo from '../images/logos/skora.png'
import uniproLogo from '../images/logos/unipro.png'
import justiceLogo from '../images/logos/justice.png'
import wilsonLogo from '../images/logos/wilson.png'


const brands = [
    {
        text: "Al1ve",
        img: al1veLogo,
        url: "al1ve",
    },
    {
        text: "Apana",
        img: apanaLogo,
        url: "apana",
    },
    {
        text: "Copper Compression",
        img: copperLogo,
        url: "copper-compression",
    },
    {
        text: "Fila",
        img: filaLogo,
        url: "fila",
    },
    {
        text: "Justice",
        img: justiceLogo,
        url: "justice"
    },
    {
        text: "Layer 8",
        img: layer8Logo,
        url: "layer-8",
    },
        {
        text: "skechers",
        img: skechersLogo,
        url: "skechers",
    },
    {
        text: "Skora",
        img: skoraLogo,
        url: "skora",
    },
    {
        text: "Unipro",
        img: uniproLogo,
        url: "unipro",
    },
    {
        text: "wilson",
        img: wilsonLogo,
        url: "wilson",
    },
]

export default brands
