import * as React from "react";
import tw from "twin.macro"; // eslint-disable-line

const Footer = () => {
    return (
        <div tw='py-8 bg-gray-800'>
            <div tw='text-center text-white font-sans text-xs'>
                Copyright {new Date().getFullYear()} All Rights Reserved
            </div>
        </div >
    )
}

export default Footer
