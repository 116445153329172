import * as React from "react";
import tw from 'twin.macro';

const ContainerDiv = tw.div`
    max-w-screen-2xl
    m-auto
    bg-gray-50
`


const Container = ({ children }) => {
    return (
        <ContainerDiv>
            {children }
        </ContainerDiv>
    )
}

export default Container
