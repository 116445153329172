import React from 'react'
import { GlobalStyles } from 'twin.macro'
import GlobalFonts from '../fonts/fonts';
import Nav from "../components/nav";
import Container from "../components/container";
import Footer from "../components/footer";

const Layout = ({ children, ...rest }) => (
  <div style={ { backgroundColor: '#212026' } } { ...rest }>
    <GlobalFonts />
    <GlobalStyles />
    <Container>
      <Nav />
      { children }
      <Footer />
    </Container>
  </div >
)

export default Layout
