import { createGlobalStyle } from 'styled-components';

import avantMd from './ITCAvantGardeStd-Bk.woff'
import avantMd2 from './ITCAvantGardeStd-Bk.woff2'

import avantDm from './ITCAvantGardeStd-Demi.woff'
import avantDm2 from './ITCAvantGardeStd-Demi.woff2'

import gibsonSemiBold from './Gibson-SemiBold.woff'
import gibsonSemiBold2 from './Gibson-SemiBold.woff2'
import gibsonLight from './Gibson-Light.woff'
import gibsonLight2 from './Gibson-Light.woff2'

export default createGlobalStyle`

    @font-face {
        font-family: 'Gibson';
        src: url(${gibsonLight2}) format('woff2'),
             url(${gibsonLight}) format('woff');
        font-weight: normal;
        font-style: normal;
        font-display: block;
    }

    @font-face {
        font-family: 'Gibson';
        src: url(${gibsonSemiBold2}) format('woff2'),
             url(${gibsonSemiBold}) format('woff');
        font-weight: 600;
        font-style: normal;
        font-display: block;
    }

    @font-face {
        font-family: 'ITC Avant Garde Gothic';
        src: url(${avantMd2}) format('woff2'),
             url(${avantMd}) format('woff');
        font-weight: normal;
        font-style: normal;
        font-display: block;
    }

    @font-face {
        font-family: 'ITC Avant Garde Gothic';
        src: url(${avantDm2}) format('woff2'),
             url(${avantDm}) format('woff');
        font-weight: 700;
        font-style: normal;
        font-display: block;
    }

`;
